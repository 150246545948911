import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';

import {
  useAuth,
  useUser
} from "@clerk/clerk-react";

const environment = process.env.REACT_APP_ENVIRONMENT; //prod or dev

let MAIN_API_NAME = "face_swap_ai"

let MAIN_API_URL = "https://funquiz.app/modular2/face=swap-ai-1"

function UserEmailAddress() {
  const { isLoaded, isSignedIn, user } = useUser();

  if (!isLoaded || !isSignedIn) {
    return "";{/*null;*/}
  }
  return user.primaryEmailAddress.emailAddress;
}

function FxInputForm3() {
    const [imageURL1, setImageURL1] = useState(null);
    const [imageURL2, setImageURL2] = useState(null);

    const [selectedFile1, setSelectedFile1] = useState(null);
  
    const handleFileChange1 = (event) => {
      setSelectedFile1(event.target.files[0]);
      setImageURL1(URL.createObjectURL(event.target.files[0]));
    };


    const [selectedFile2, setSelectedFile2] = useState(null);
  
    const handleFileChange2 = (event) => {
      setSelectedFile2(event.target.files[0]);
      setImageURL2(URL.createObjectURL(event.target.files[0]));
    };

    
  
    //const authenticatedFetch = useFetch();
    const { getToken } = useAuth();
    const emailAddr = UserEmailAddress();

    const [textOutput, setTextOutput] = useState('');

    const inputProps = {
      inputProps: {
        style: { textAlign: 'center' },
      },
    };
  
    const handleTextOutputChange = event => {
        {/*console.log('Saving value');
    console.log(event.target.value);*/}
        setTextOutput(event.target.value);
    };


    const [textInput1, setTextInput1] = useState('');
    const handleTextInputChange1 = event => {
        {/*console.log('Saving value');
    console.log(event.target.value);*/}
        setTextInput1(event.target.value);
    };

    const [textInput2, setTextInput2] = useState('');
    const handleTextInputChange2 = event => {
        {/*console.log('Saving value');
    console.log(event.target.value);*/}
        setTextInput2(event.target.value);
    };
  
    const inputProps1 = {
      inputProps: {
        style: { textAlign: 'center' },
      },
    };
  
    function submitInput() {
    }
    
    function handleUpload() {
      setTextOutput("Loading...");
      // Implement upload logic here, e.g., using Fetch API or a library like Axios
      if (selectedFile1 && selectedFile2) {
        const formData = new FormData();
        formData.append('image1', selectedFile1);
        formData.append('image2', selectedFile2);
        formData.append('email', emailAddr);
        formData.append('env', environment);
        formData.append('api_name',MAIN_API_NAME)
        // console.log(selectedFile);
  
        const fetchX = async () => {
          const myToken = await getToken();
          // Make an API request to upload the file
          fetch(MAIN_API_URL, {
            mode: 'cors',
            method: 'POST',
            headers: {
              'Authorization': `Bearer ${myToken}`,
            },
            body: formData,
          })
          .then(response => response.json())
          .then(response => {
            // Handle response from server
            console.log(response["message"]);
            setTextOutput(response["message"]);
          })
          .catch(error => {
            // Handle error
            setTextOutput("Sorry, action failed");//error.message);
            console.error(error);
            //console.log("error")
          });
        };
        fetchX();
      }
    }

    const myaspectstyle = {
      width: "100%",
      height: "100%"
    }; {/*aim is to preserve aspect ratio of image*/}

    let imgElement;
    let outputDisplayText;
    if (textOutput == "")
    {
      imgElement = <p></p>;
      outputDisplayText = <p></p>;
    }
    else if (isValidHttpUrl(textOutput))
    {
      imgElement = <img src={textOutput} alt="a url" style={myaspectstyle}></img>
      outputDisplayText = <h2>Output</h2>;
    }
    else {
      /* console.log("greet2 $textOutput"); */
      imgElement = <p>{textOutput}</p>;
      outputDisplayText = <h2>Output</h2>;
    }


    let imgElement1;
    if (imageURL1 && imageURL1.length > 2)
    {
      
      imgElement1 = <img src={imageURL1} alt="a url 1"  style={myaspectstyle}></img>
    }
    else {
      imgElement1 = <p></p>
    }

    let imgElement2;
    if (imageURL2 && imageURL2.length > 2)
    {
      
      imgElement2 = <img src={imageURL2} alt="a url 2" style={myaspectstyle}></img>
    }
    else {
      imgElement2 = <p></p>
    }

    


  
    return (
      <div>
        <Box display="flex" 
             marginTop={5} 
             alignItems="center" 
             bgcolor="white"
             justifyContent="center">
        <p>Choose a source image with a face in it</p>
        </Box>
        <Box display="flex" 
             marginTop={2} 
             alignItems="center" 
             bgcolor="white"
             justifyContent="center">
        <input type="file" accept="image/*" onChange={handleFileChange1} />
        <br />
        
        {/*<button onClick={handleUpload}>Upload</button>*/}
        </Box>
        <Box display="flex" 
             marginTop={2} 
             marginBottom={8} 
             marginLeft={0} 
             marginRight={0} 
             alignItems="center" 
             bgcolor="white"
             justifyContent="center"
             objectFit="contain">
          {imgElement1}
        </Box>
        <Box display="flex" 
             marginTop={5} 
             alignItems="center" 
             bgcolor="white"
             justifyContent="center">
        <p>Choose a target image with a face in it</p>
        </Box>
        <Box display="flex" 
             marginTop={2} 
             alignItems="center" 
             bgcolor="white"
             justifyContent="center">
        <input type="file" accept="image/*" onChange={handleFileChange2} />
        {/*<button onClick={handleUpload}>Upload</button>*/}
        </Box>
        <Box display="flex" 
             marginTop={2} 
             marginBottom={8} 
             marginLeft={0} 
             marginRight={0} 
             alignItems="center" 
             bgcolor="white"
             justifyContent="center">
          {imgElement2}
        </Box>

        {/*<Box display="flex" 
             marginTop={5} 
             paddingLeft={5} 
             paddingRight={5} 
             alignItems="center" 
             bgcolor="white"
             justifyContent="center">
        <TextField 
          id="outlined-basic" 
          label="Add some extra instructions..." 
          variant="outlined" 
          InputProps={inputProps}
          value={textInput1}
          justifyContent="center"
          fullWidth
          
          multiline
          onChange={handleTextInputChange1}/>
        </Box>*/}
        <Box display="flex" 
             marginTop={5} 
             marginBottom={5} 
             alignItems="center" 
             bgcolor="white"
             justifyContent="center">
        <Button variant="contained"
                onClick={handleUpload}>Submit Input</Button>
        </ Box>

        <Box display="flex" 
             alignItems="center" 
             bgcolor="white"
             marginTop={0} 
             marginBottom={0}
             justifyContent="center">
          {outputDisplayText}
        </Box>
        <Box display="flex" 
             alignItems="center" 
             bgcolor="white"
             marginTop={0} 
             marginBottom={15}
             marginLeft={0} 
             marginRight={0} 
             justifyContent="center">
          {imgElement}
        </Box>
        {/*<Box marginTop={0} marginBottom={5}>
        <div
          style={{
            whiteSpace: "pre-line", // Preserve line breaks and spaces
            height: "300px",       // Set a fixed height
            overflow: "auto",      // Enable scrolling if content overflows
            border: "1px solid #ccc",
            padding: "10px",
          }}
        > {textOutput} </div>
        </Box>*/}
        
      </div>
    );
  }

  function isValidHttpUrl(string) {
    let url;
    
    try {
      url = new URL(string);
    } catch (_) {
      return false;  
    }
  
    return url.protocol === "http:" || url.protocol === "https:";
  }

  export default FxInputForm3;